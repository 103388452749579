import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import LangTable from 'components/Web_User_Interface/720p_Series/Software/Language/langTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software Menu // Language",
  "path": "/Web_User_Interface/720p_Series/Software/Language/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.",
  "image": "./WebUI_720p_SearchThumb_Software_Language.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Software_Language.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Software Menu // Language' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.' image='/images/Search/WebUI_720p_SearchThumb_Software_Language.png' twitter='/images/Search/WebUI_720p_SearchThumb_Software_Language.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Software/Sprache/' locationFR='/fr/Web_User_Interface/720p_Series/Software/Language/' crumbLabel="Language" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "software-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-menu",
        "aria-label": "software menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Menu`}</h2>
    <h3 {...{
      "id": "language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#language",
        "aria-label": "language permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85e5524ea49cd4ad166810873dc74918/6bbf7/WebUI-Software_Language.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAACmklEQVQ4y5XSa0/aUBwG8POtln2Mbd9gW+L3cK+VxAVUyq2TmzAYNqjFtpy2XHoBsyioq2kLAuHWQlsYwmIMC7AlzYxm/F6dPOf885ycHIBh2PsPH1+9fv3m7bu9vT2PxxsIBBDEgyBuj9cTQNGwy+XZ2UG83i8o6vX6/H6/y+VE3EgoFAIYhrl2dzc3P21vO0iSLJfLpVJJEASO43ieFwRB5Di+UOS4xZrn+UXK8xDCjY0NEI1EDg8P08fHBEFI0o+bm+t8Pi/d3o7HY2PF/MOwGY/HbrcbxOPxz0vhcFhV1W6322g02u1273mdTseyrGKxCHAcR9wep9MZi8V6vd7d3Z0k3Q6HQ+N5g8HANE0a0qDTbmt9bT6fG4bRaDTqtfpV9UrXB8PnaZqm6zrDMODk5ATDsOySoiiKrFSrVU3XDMNYHR0s2Yd1Xbcsi6Zp4PP5HA5HLBZPpVKSJHW73dXe32cyR0umzXA4nEwmDM2Ag4Og3++LRqNfEwlZluv1eqVSubIpLdmTy8vLZrO5aA4GgyiKxpdkWVZVtVqtXtuIoiiIoj2pVCqtVosiSYCi6P7+/hGGJZNJSZImk4llWSObn0v2xDTNh4cHgiBALBaLRKLBYCixvLZlWbquD16kadp0Oj07OwOiKNIMzeZyhUJBURTLsv5526d0XZ/NZplMBlAUheM4QRCZTKZWq603zPN8NpuFkM7n8+s14zhgGZYiSQhhNgtVVf3/YRzHAc8LOZblityqeTQarTHMsjmGphmWpWl67WaBF8ql8nn5nGVZWZYNw1j9+xf0+/37+/vT01MgCmK5dH7x/QJC2Gy1Hh8fZ7PZrxdNp9P5fA4hBBBCiiIpiiRJ8sgmlUp9W0g+lUgk0un01tbWb+VOH49FV5hoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85e5524ea49cd4ad166810873dc74918/e4706/WebUI-Software_Language.avif 230w", "/en/static/85e5524ea49cd4ad166810873dc74918/d1af7/WebUI-Software_Language.avif 460w", "/en/static/85e5524ea49cd4ad166810873dc74918/04ac8/WebUI-Software_Language.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85e5524ea49cd4ad166810873dc74918/a0b58/WebUI-Software_Language.webp 230w", "/en/static/85e5524ea49cd4ad166810873dc74918/bc10c/WebUI-Software_Language.webp 460w", "/en/static/85e5524ea49cd4ad166810873dc74918/d8378/WebUI-Software_Language.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85e5524ea49cd4ad166810873dc74918/81c8e/WebUI-Software_Language.png 230w", "/en/static/85e5524ea49cd4ad166810873dc74918/08a84/WebUI-Software_Language.png 460w", "/en/static/85e5524ea49cd4ad166810873dc74918/6bbf7/WebUI-Software_Language.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85e5524ea49cd4ad166810873dc74918/6bbf7/WebUI-Software_Language.png",
              "alt": "Web User Interface - 720p Series - Software Language",
              "title": "Web User Interface - 720p Series - Software Language",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can choose the language of the web user interface. The web user interface will reload after you click Submit.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LangTable mdxType="LangTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      